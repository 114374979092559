.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    padding: calc(var(--section-padding) / 6) var(--section-padding);

    
    color: whitesmoke;
    background-color: var(--cwo-black);

    
    /*
    background-color: whitesmoke;
    color: #131313;
    box-shadow: inset 0px 8px 6px -6px #000, inset 0px -8px 6px -6px #000;
    */
}

.about-heading {
    flex-basis: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.about-text {
    flex-basis: 67%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:justify;

    font-size: 1.2vw;
    font-weight: 400;
    line-height: 1.6vw;

    padding: 24px;
    border-radius: 12px;
    /*
    border: 3px solid #424242;
    */
}

@media only screen and (max-width: 1366px) {
    .about {
        padding: calc(var(--section-padding) / 6) calc(var(--section-padding) / 3);
    }
    .about-text {
        font-size: 2.5vw;
        line-height: normal;
    }

}


@media only screen and (max-width: 600px) {
    .about {
        padding: calc(var(--section-padding) / 6) 0;
    }
    .about-text {
        font-size: 3.5vw;
        line-height: normal;
    }
    .video-responsive iframe{
        max-width: 80vw !important;
        height: 300px;
    }

  }
  
