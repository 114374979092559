.masonry-gallery {
  columns: 230px;
}

.masonry-image {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1em;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.enlarged-container {
  max-width: 90%;
  max-height: 90%;
}

.enlarged-image {
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 10px;
}
  
  /* Responsive design */
@media (max-width: 768px) {
  .masonry-gallery {
    columns: 150px;
  }
}
  
@media (max-width: 480px) {
  .masonry-gallery {
    columns: 130px;
  }
}