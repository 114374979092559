.cwo {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  position: relative;
}
