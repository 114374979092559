.placetime {
    padding: calc(var(--section-padding) / 6) var(--section-padding);
    background-color: var(--cwo-black);
    color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
}

.placetime-info {
    flex-basis: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.placetime-info .info-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.placetime-info .info {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 1.6vw;
}

.placetime-map {
    flex-basis: 67%;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.map {
    width: 100%;
    height: 30vw;
    border: 2px solid #00c2ff;


    border-radius: 4px;
    overflow: hidden;
}

.map button {
    all: unset;
}

.map button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1366px) {
    .placetime {
        padding: calc(var(--section-padding) / 6) calc(var(--section-padding) / 3);
    }
    .placetime-info .info  {
        font-size: 2.5vw;
        line-height: normal;
    }
    .map {
        width: 100%;
        height: 80vw;
    }

}

@media only screen and (max-width: 600px) {
    .placetime {
        padding: calc(var(--section-padding) / 6) 0;
    }
    .placetime-info .info  {
        font-size: 3.5vw;
        line-height: normal;
    }
    .map {
        width: 100%;
        height: 120vw;
    }

  }


  