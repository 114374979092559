* {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

:root {
  --section-padding: 358px;
  --cwo-black: #131313;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #131313;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button{
  all: unset;

  border: 2.5px solid #0491C1;
  background: linear-gradient(60deg, #086082, #00c2ff);
  color: #fff;
  
  background-position: middle;
  background-size: 100% 100%;
  transition: all .5s ease;
}

button:hover {
  cursor: pointer;
  background-size: 2500% 100%;
  background-position: right bottom;
  scale: 1.01;
}

button a {
  all: unset;
}



.blue-text{
-webkit-text-fill-color: #0000;
    animation: textclip 8s linear infinite;
    background: linear-gradient(45deg, #086082, #00c2ff);
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg, #086082, #00c2ff 50%, #086082);
    background-size: initial;
    background-size: 200% auto;
    color: #fff;
    color: #0000;
    z-index: 1038;
  }

.headline {
  font-size: 2.8vw;
  font-weight: 450;
  color: #00c2ff;
}




@media only screen and (max-width: 600px) {

  button a{
    font-size: 6vw;
  }

  .headline {
    font-size: 6vw;

  }
}

@media only screen and (max-width: 1366px) {

  button a{
    font-size: 8vw;
  }

  .headline {
    font-size: 7vw;

  }
}


