.footer {
    padding: calc(var(--section-padding) / 8);
    background-color: var(--cwo-black);
    color: whitesmoke;

    display: flex;
    justify-content: space-around;

    box-sizing: border-box;
    border-top: 1px solid whitesmoke;
}

.ig-handle, .email-handle {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 1vw;
    transition: all .2s linear;
}

.ig-handle:hover {
    cursor: pointer;
    color: #00c2ff;
}

.person-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.person-info .name-function {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.person-info .name {
    font-size: 1.4vw;
    font-weight: 450;
}

.person-info .function {
    font-size: .8vw;
    font-weight: 400;
}

@media only screen and (max-width: 1366px) {
    .footer {
        padding: calc(var(--section-padding) / 8) calc(var(--section-padding) / 6);
        flex-direction: row;
        gap: 60px;
    }

    .person-info .name {
        font-size: 2.5vw;
    }

    .person-info .function {
        font-size: 2vw;
    }

    .person-info .name-function {
        gap: 4px;
    }

    .ig-handle, .email-handle {
        gap: 6px;
        font-size: 1.5vw;
    }

  }

@media only screen and (max-width: 600px) {
    .footer {
        padding: calc(var(--section-padding) / 8) calc(var(--section-padding) / 4);
        flex-direction: column;
        gap: 40px;
    }

    .person-info .name {
        font-size: 5vw;
    }

    .person-info .function {
        font-size: 3vw;
    }

    .person-info .name-function {
        gap: 4px;
    }

    .ig-handle, .email-handle {
        gap: 6px;
        font-size: 3vw;
    }

  }


  